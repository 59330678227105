import React from 'react';

import { SEO } from '../../components/seo';

import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';

import Form from './form';

import '../../reset.css';

const Orcamento = () => (
  <>
    <SEO title="Orçamento" />
    <Header title="Orçamento" />

    <Form />

    <Footer />
  </>
);

export default Orcamento;
