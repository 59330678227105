import React, { useState } from 'react';
import axios from 'axios';

import LoadingGif from '../../../assets/images/loading.gif';

import './form.scss';

function Form() {
  const [resposta, setResposta] = useState('off');
  const [loading, setLonding] = useState('');
  const [campos, setCampos] = useState({
    nome: '',
    email: '',
    telefone: '',
    metragem: '',
    tecido: '',
    descricao: '',
    anexo: ''
  });
  function handleInputChange(event) {
    if (event.target.name === 'anexo')
      campos[event.target.name] = event.target.files[0];
    else campos[event.target.name] = event.target.value;
    setCampos(campos);
  }
  function send({ reset }) {
    setLonding('true');
    const formData = new FormData();

    Object.keys(campos).forEach(key => formData.append(key, campos[key]));
    axios
      // .post('http://localhost:5000/orcamento', formData, {
      .post('https://sedafio-mailer.herokuapp.com/orcamento', formData, {
        headers: {
          'Content-Type': `multipart/form-data; boundary=${formData._boundary}`
        }
      })
      .then(response => {
        console.log(response.data);
        setResposta('on');
        setLonding('false');
      })
      .catch(error => {
        console.log(error);
        setLonding('false');
      });
  }

  function handleFormSubmit(event) {
    event.preventDefault();
    console.log(campos);
    send(campos);
  }
  return (
    <>
      <section id="form" className="container">
        <div className="form-wrapper">
          <div className="title-form">
            <h1>Solicite o seu orçamento agora mesmo!</h1>
            <p>É simples, rápido e prático.</p>
          </div>
          <div className="formulario-wrapper">
            {resposta == 'off' ? (
              <form onSubmit={handleFormSubmit}>
                <input
                  type="text"
                  name="nome"
                  placeholder="Nome:"
                  onChange={handleInputChange}
                />
                <input
                  type="email"
                  name="email"
                  placeholder="E-mail:"
                  onChange={handleInputChange}
                />
                <input
                  type="tel"
                  name="telefone"
                  placeholder="Telefone:"
                  onChange={handleInputChange}
                />
                <input
                  type="text"
                  placeholder="Metragem:"
                  name="metragem"
                  onChange={handleInputChange}
                />
                <select name="tecido" onChange={handleInputChange}>
                  <option value="" defaultValue>
                    Tipo de Tecido:
                  </option>
                  <option value="oxford">Oxford</option>
                  <option value="bember">Bember</option>
                  <option value="tactel">Tactel</option>
                  <option value="dryfit">Dryfit</option>
                  <option value="veludo">Veludo</option>
                  <option value="suplex">Suplex</option>
                  <option value="tricoline misto">Tricoline Misto</option>
                  <option value="citystrash">Citystrash</option>
                  <option value="gabardine">Gabardine</option>
                  <option value="neoprene">Neoprene</option>
                  <option value="halanca">Halanca</option>
                  <option value="chifon">Chifon</option>
                  <option value="outro">Outro</option>
                </select>
                <textarea
                  name="descricao"
                  id="descricao"
                  cols="30"
                  rows="10"
                  placeholder="Descrição:"
                  onChange={handleInputChange}
                ></textarea>
                <input
                  type="file"
                  id="anexo"
                  name="anexo"
                  onChange={handleInputChange}
                ></input>
                <div className="buttons-form">
                  {loading == '' ? (
                    <input type="submit" value="Enviar" id="enviar" />
                  ) : null}
                  {loading == 'true' ? <img src={LoadingGif} /> : null}
                </div>
              </form>
            ) : (
              <p className="resposta">
                Obrigado pela mensagem! Em breve, entraremos em contato.
              </p>
            )}
          </div>
        </div>
      </section>
    </>
  );
}

export default Form;
